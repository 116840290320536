import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { TagsComponent } from "./tags.component";
import { TagFormComponent } from "./tag-form/tag-form.component";
import { TagListComponent } from "./tag-list/tag-list.component";

// Routes
import { TagsRouting } from "./tags.routing";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        TagsRouting,
        MatTooltipModule,
        TourMatMenuModule
    ],
    declarations: [TagsComponent, TagFormComponent, TagListComponent]
})
export class TagsModule {}
