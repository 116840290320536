<div class="panel-container">
    <div id="left-container" class="left-container flex-basis-0" #leftContainer>
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 title="{{ 'TAGS' | translate }}" tourAnchor="firstTagAnchor">
                    {{ "TAGS" | translate
                    }}<fa-icon
                        class="ms-2 tourStartButton"
                        icon="question-circle"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_TAGS_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        size="xs"
                        (click)="tourService.start(); mixpanelService.sendEvent('start tags feature guide')"
                    ></fa-icon>
                </h1>
            </div>
            <div class="actions">
                <button type="button" class="btn btn-outline-primary me-2" routerLink="/{{ urls.configuration.tags }}/new">
                    <fa-icon icon="plus" size="sm"></fa-icon>
                    <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }}</span>
                </button>
                <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                    <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                    <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                </button>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel>
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'tag'"
                    [displayTableName]="'TAGS' | translate"
                    [data]="tags$ | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    [showReport]="false"
                    [isColumnSelectionNeeded]="true"
                    [selectable]="false"
                    [hoverable]="false"
                >
                    <div *ngIf="selectedRows.length > 0">
                        <app-zx-action-buttons
                            [toggleMuteButton]="false"
                            [enableButton]="false"
                            [toggleStateButton]="false"
                            [editButton]="false"
                            (multiDelete)="multiDelete($event)"
                        >
                        </app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
</div>
