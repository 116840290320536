import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { TagsService } from "../tags.service";
import { Tag } from "../../../../models/shared";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: "app-tag-form",
    templateUrl: "./tag-form.component.html",
    providers: [TitleCasePipe]
})
export class TagFormComponent implements OnInit, OnDestroy {
    tag: Tag;
    tagId: number;
    tagNames: string[];
    action: string;

    submitted = false;
    minLength = 2;
    maxLength = 100;
    isEdit = false;
    isClone = false;

    vipTagControl = new FormControl<Boolean>(false);

    form = new FormGroup({
        vip: this.vipTagControl
    });
    loading = true;
    saving = false;
    constants = Constants;

    private tagsSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private ts: TagsService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.tagId = parseInt(params.get("id"), 10);
            this.action = params.get("action");
            if (this.tagId) {
                this.ts.refreshTags();

                this.ts.tags.pipe(take(1)).subscribe(tags => {
                    this.tag = Object.assign(
                        {},
                        tags.find(t => t.id === this.tagId)
                    );
                    this.prepForm();
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        });
    }

    prepForm() {
        if (this.action) {
            if (this.action === "edit") this.isEdit = true;
        }

        if (!this.tag && !this.isClone && !this.isEdit) {
            this.tag = new Tag();
            this.tag.name = "";
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("TAG") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.tag && this.tag.name ? this.tag.name : "")
        );
    }

    ngOnInit() {
        this.ts.refreshTags();

        this.tagsSubscription = this.ts.tags.subscribe((tags: Tag[]) => {
            this.tagNames = tags.map(t => t.name);
            if (this.isEdit) this.tagNames = tags.filter(t => t.id !== this.tagId).map(t => t.name);
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.tagsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        const formControls = this.form.controls;

        const model: Tag = {
            name: this.tag.name,
            vip: !!formControls.vip.value
        };

        if (this.isEdit) {
            const result = await this.ts.updateTag(this.tag, model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.tags]);
            } else this.saving = false;
        } else {
            const result = await this.ts.addTag(model);
            if (result !== false) {
                this.saving = false;
                this.router.navigate([Constants.urls.configuration.tags]);
            } else this.saving = false;
        }
    }

    cancel() {
        this.router.navigate([Constants.urls.configuration.tags]);
    }

    vipChange() {
        if (this.tag) {
            this.vipTagControl.setValue(this.tag.vip);
        }
        this.vipTagControl.setValue(!this.vipTagControl.value);
        this.tag.vip = !!this.vipTagControl.value;
    }
}
