import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";

// Components
import { TagsComponent } from "./tags.component";
import { TagListComponent } from "./tag-list/tag-list.component";
import { TagFormComponent } from "./tag-form/tag-form.component";

// Resolver
import { TagListResolverService } from "./tag-list/tag-list-resolver.service";

const tagRoutes: Routes = [
    {
        path: Constants.urls.configuration.tags + "/new",
        component: TagFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_ACCESS_TAG",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/DIBaMQ"
                }
            ]
        }
    },
    {
        path: Constants.urls.configuration.tags + "/:id/:action",
        component: TagFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.tags,
        component: TagsComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.TAGS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/AYBaMQ"
                },
                {
                    menu: "HELP_MENU.CREATE_ACCESS_TAG",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/DIBaMQ"
                }
            ]
        },
        children: [
            {
                path: "",
                component: TagListComponent,
                resolve: {
                    tag: TagListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(tagRoutes)],
    exports: [RouterModule]
})
export class TagsRouting {}
