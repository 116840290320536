import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { TagsService } from "../tags.service";
import { Tag } from "../../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class TagListResolverService implements Resolve<Tag[]> {
    constructor(private tps: TagsService) {}

    resolve(): Observable<Tag[]> | Observable<never> {
        return new Observable((observe: Subscriber<Tag[]>) => {
            this.tps.refreshTags().subscribe((tags: Tag[]) => {
                observe.next(tags);
                observe.complete();
            });
        });
    }
}
